<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-6">
        Write the balanced molecular equation for each of the chemical reactions described below:
      </p>

      <p class="mb-3">
        a) Solid copper reacts with nitric acid to form aqueous copper(II) nitrate, gaseous nitrogen
        monoxide and liquid water.
      </p>
      <chemical-notation-input
        v-model="inputs.reactionA"
        dense
        :show-note="false"
        class="mb-7"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) Solid tetraphosphorus decoxide reacts with liquid water to form phosphoric acid.
      </p>
      <chemical-notation-input
        v-model="inputs.reactionB"
        dense
        :show-note="false"
        class="mb-7"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question345',
  components: {
    ChemicalNotationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        reactionA: null,
        reactionB: null,
      },
    };
  },
};
</script>
